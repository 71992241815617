/**
 * @flow
 */

const isEmbedded = () => {
  try {
    // Accessing window.top can raise depending on same-origin policy.
    return window !== window.top;
  } catch (e) {
    // If you're violating same-origin then obviously you're not in the top-most window, so you're embedded.
    return true;
  }
};

document.addEventListener("DOMContentLoaded", () => {
  if (isEmbedded()) {
    document.querySelectorAll(".only-display-in-iframe").forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.style.display = "initial";
    });
  }
});
